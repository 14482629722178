import { useDispatch, useSelector } from "react-redux";
import { useAPI } from "../../../../Common/utils/APIUtils";
import {
  setAccountCreationRefused,
  setInspectionInfo,
  setItemInfo,
  setQuestionnaireStartTime,
} from "../../redux/slices/PDISlice";
import {
  NavigationBlocksMapper,
  PurchaseChannels,
  PurchaseChannelsIDMappers,
} from "../../../../Common/Constants";
import { FormattedMessage } from "react-intl";
import { VpIcon } from "@vtmn-play/react";
import NavigationBlocks from "../../../../Common/components/NavigationBlocks";
import { handleError, handleLoading } from "../../../../Common/utils/helpers";

const PDIInspectionHomepage = () => {
  const dispatch = useDispatch();
  const { activeAppType } = useSelector((state) => state.userStore);
  const { data, error, isLoading } = useAPI(
    `/api/v1/purchase_channels?inspection_type=${activeAppType}`
  );
  const handleBlockClick = ({ purchaseChannel }) => {
    // On click of the block, we need to set type of inspection (online, physical etc)
    dispatch(
      setInspectionInfo({
        purchaseChannel: PurchaseChannels[purchaseChannel],
      })
    );
    dispatch(setQuestionnaireStartTime(Date.now()));
    dispatch(setAccountCreationRefused(false));
    dispatch(
      setItemInfo({
        modelName: "",
        modelId: "",
        orderNumber: "",
        loyaltyCard: "",
      })
    );
  };

  if (!data && isLoading) return handleLoading();
  if (!data && error) return handleError();
  if (data?.items?.length) {
    // Based on the ID of the purchase channel which is being sent by the Backend, we get the Name of the purchase channel
    const purchaseMapperID = data?.items?.map(
      (item) => PurchaseChannelsIDMappers[item?.["id"]]
    );
    const getNavigationBlocksData = purchaseMapperID.map(
      (purchase_channel_name) =>
        NavigationBlocksMapper[activeAppType]?.channels?.[purchase_channel_name]
          ?.meta
    );
    const navigationBlocks = getNavigationBlocksData.map((item) => ({
      title: <FormattedMessage id={item.phraseKey} />,
      url: item.redirectUrl,
      icon: <VpIcon name={item.vpIcon} size={28} />,
      onBlockClick: () => handleBlockClick(item.blockClickParams),
    }));

    return (
      <div className="vtmn-w-full vtmn-px-3 vtmn-my-3">
        <h1 className="vtmn-font-bold vtmn-text-2xl">
          <FormattedMessage id="pdi.form.inspection.type.title" />
        </h1>
        <NavigationBlocks blocks={navigationBlocks} />
      </div>
    );
  }
  return null;
};
export default PDIInspectionHomepage;
