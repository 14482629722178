import { fetchDataWithHeaders } from "../utils/APIUtils";
export const getOrderInfo = async (id) => {
  try {
    // -------------------------------- DO NOT DELETE THE BELOW CODE BLOCK -----------------------------
    // let reference_type = "trackingNumber";
    // const lowerCaseId = id.toLowerCase();
    // if (lowerCaseId.startsWith("sg")) {
    //   reference_type = "shippingGroupID";
    // } else if (lowerCaseId.startsWith("gb")) {
    //   reference_type = "orderID";
    // }
    // if (reference_type === "trackingNumber") {
    //   id = id.replace(/\D/g, "");
    // }
    // const orderInfo = await fetchDataWithHeaders(
    //   `/api/v1/orders/${id}?reference_type=${reference_type}`
    // );
    // return orderInfo?.body?.[0];
    // ------------------------------------------------------------------------------------------------
    // As we are unsure of the type of ID scanned, we are making 3 different API calls(Search by orderID, search by trackingNumber ID, search by shippingGroupID)
    // Temparory fix, will change later and make backend API to handle this.

    let possible_order_id_types = [
      "orderID",
      "shippingGroupID",
      "trackingNumber",
    ];
    for (let type of possible_order_id_types) {
      try {
        const response = await fetchDataWithHeaders(
          `/api/v1/orders/${id
            ?.replace(/\s+/g, "")
            .toUpperCase()}?reference_type=${type}`
        );
        if (response?.body?.length) {
          return response?.body?.[0];
        }
      } catch (error) {
        console.error(`Error fetching data for ${type}:`, error.message);
      }
    }
    return [];
  } catch (error) {
    console.error("Error in getting order info: ", error);
    return [];
  }
};

export const getSerialNumberFromAPIC = async (APIC) => {
  try {
    try {
      const response = await fetchDataWithHeaders(
        `/api/v1/orders/serial_number/${APIC?.replace(
          /\s+/g,
          ""
        ).toUpperCase()}`
      );
      if (response?.body?.length) {
        return (
          response?.body?.find(
            (item) => item?.apicCode?.toUpperCase() === APIC?.toUpperCase()
          ) || {}
        );
      }
    } catch (error) {
      console.error(`Error fetching serial number:`, error.message);
    }
    return {};
  } catch (error) {
    console.error("Error in getting serial number: ", error);
    return {};
  }
};
